import React from "react"
import { Swiper } from "swiper/react"
import ChevronLeft from "../../assets/images/icon-chevron-left.inline.svg"
import ChevronRight from "../../assets/images/icon-chevron-right.inline.svg"
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
} from "swiper"
import { DEVICES } from "../../config"

SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade])

function CustomSwiper({
  id,
  children,
  className,
  arrowSize = 48,
  bulletSize = 16,
  ...props
}) {
  return (
    <div
      css={`
        --arrowSize: ${arrowSize}px;
        --bulletSize: ${bulletSize}px;
        --gap: 40px;

        position: relative;

        .swiper-next {
          width: var(--arrowSize);
          height: var(--arrowSize);
          position: absolute;
          z-index: 10;
          right: var(--gap);
          left: auto;
          top: calc(50% - ${arrowSize / 2}px);
          cursor: pointer;
          fill: var(--text);
          opacity: 0.4;
          transition: opacity 250ms ease;

          &:hover {
            opacity: 1;
          }
        }

        .swiper-prev {
          width: var(--arrowSize);
          height: var(--arrowSize);
          position: absolute;
          z-index: 10;
          right: auto;
          left: var(--gap);
          top: calc(50% - ${arrowSize / 2}px);
          cursor: pointer;
          fill: var(--text);
          opacity: 0.4;
          transition: opacity 250ms ease;

          &:hover {
            opacity: 1;
          }
        }

        .swiper-pagination {
          bottom: var(--gap);
          width: 100%;
          text-align: center;
          left: 0;
        }

        .swiper-pagination-bullet {
          width: var(--bulletSize);
          height: var(--bulletSize);
          border: solid 2px #d6d5d5;
          opacity: 1;
          background: transparent;
          margin: 0 4px;
        }

        .swiper-pagination-bullet-active {
          background: #d6d5d5;
        }

        .swiper-prev.swiper-button-disabled,
        .swiper-next.swiper-button-disabled {
          display: none;
        }

        @media (max-width: ${DEVICES.desktop}px) {
          --gap: 20px;
        }
      `}
      className={className}
    >
      <Swiper {...props}>{children}</Swiper>
      <ChevronLeft className={`swiper-prev ${id}-prev`} />
      <ChevronRight className={`swiper-next ${id}-next`} />
      <div className={`swiper-pagination ${id}-pagination`}></div>
    </div>
  )
}

export default CustomSwiper
